/* Message box styles */

.messages-container {
    overflow-y: scroll;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .message-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  
  .message-box.current-user {
    display: flex;
    flex-direction: row-reverse;
  }
  
  
  /* .message-box.current-user p {
    order: 1;
    background-color: #617285;
    color: #fff;
    margin-left: 70px;
    margin-right: 0;
  } */
  
  /* Form styles */
  
  /* .form {
    display: flex;
    padding: 5px 8px;
    align-items: center;
    background-color: #0f1720;
  }
  
  input[type="text"] {
    flex: 1;
    padding: 10px 14px;
    font-size: 16px;
    border: none;
    color: #fff;
    outline: none;
    border-radius: 20px;
    background-color: #2f3c4b;
    margin-right: 10px;
  } */
  
  

  