.slider {
  position: relative;
  /* width: 100%; */
  /* height: 70vh; */
  overflow: hidden;
  white-space: nowrap;
}

.transbox {
  opacity: 0.8;
}
.slider .slider_wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.slider_wrapper .slide {
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
}

.slide .slide_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 888;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.slide .slide_content button {
  padding: 20px 40px;
  margin-top: 30px;
  background-color: rgb(252, 40, 40);
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in;
  font-family: Poppins;
}
.slide .slide_content button:hover {
  background-color: rgb(5, 2, 2);
}
.slider .dots_wrapper {
  z-index: 999;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.dots_wrapper .dot {
  color: rgb(24, 23, 23);
  font-size: 40px;
  font-weight: 700;
  cursor: pointer;
}
.dots_wrapper .dot.active {
  color: red;
}
