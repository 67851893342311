
.swiper-slide {
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    
    object-fit: cover;
  }
  
  .swiper-pagination-bullet {
    
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    font-size: 15px;
    color: #ffffff;
    opacity: 1;
    background: rgba(11, 240, 7, 0.2);
  }
  
  .swiper-pagination-bullet-active {
    color: #000000;
    background: #ffffff;
  }
  