
:root {

  /**
   * colors
   */

  --selective-yellow: hsl(42, 94%, 55%);
  --eerie-black-1: hsl(0, 0%, 9%);
  --eerie-black-2: hsl(180, 3%, 7%);
  --quick-silver: hsl(0, 0%, 65%);
  --radical-red: hsl(351, 83%, 61%);
  --light-gray: hsl(0, 0%, 80%);
  --isabelline: hsl(36, 33%, 94%);
  --gray-x-11: hsl(0, 0%, 73%);
  --kappel_15: hsla(170, 75%, 41%, 0.15);
  --platinum: hsl(0, 0%, 90%);
  --gray-web: hsl(0, 0%, 50%);
  --black_80: hsla(0, 0%, 0%, 0.8);
  --white_50: hsla(0, 0%, 100%, 0.5);
  --black_50: hsla(0, 0%, 0%, 0.5);
  --black_30: hsla(0, 0%, 0%, 0.3);
  --kappel: hsl(170, 75%, 41%);
  --white: hsl(0, 0%, 100%);

  /**
   * gradient color
   */

  --gradient: linear-gradient(-90deg,hsl(151, 58%, 46%) 0%,hsl(170, 75%, 41%) 100%);

  /**
   * typography
   */

  --ff-league_spartan: 'League Spartan', sans-serif;
  --ff-poppins: 'Poppins', sans-serif; 

  --fs-1: 4.2rem;
  --fs-2: 3.2rem;
  --fs-3: 2.3rem;
  --fs-4: 1.8rem;
  --fs-5: 1.5rem;
  --fs-6: 1.4rem;
  --fs-7: 1.3rem;

  --fw-500: 500;
  --fw-600: 600;

  /**
   * spacing
   */

  --section-padding: 5px;

  /**
   * shadow
   */

  --shadow-1: 0 6px 15px 0 hsla(0, 0%, 0%, 0.05);
  --shadow-2: 0 10px 30px hsla(0, 0%, 0%, 0.06);
  --shadow-3: 0 10px 50px 0 hsla(220, 53%, 22%, 0.1);

  /**
   * radius
   */

  --radius-pill: 500px;
  --radius-circle: 50%;
  --radius-3: 3px;
  --radius-5: 5px;
  --radius-10: 10px;

  /**
   * transition
   */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}








/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 15px; }

.section { padding-block: var(--section-padding); }

.shape {
  position: absolute;
  display: none;
}

.has-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.h1,
.h2,
.h3 {
  color: var(--eerie-black-1);
  font-family: var(--ff-league_spartan);
  line-height: 1;
}

.h1,
.h2 { font-weight: var(--fw-600); }

.h1 { font-size: var(--fs-1); }

.h2 { font-size: var(--fs-2); }

.h3 {
  font-size: var(--fs-3);
  font-weight: var(--fw-500);
}

.section-title {
  --color: var(--radical-red);
  text-align: center;
}

.section-title .span {
  display: inline-block;
  color: var(--color);
}

.btn {
  background-color: var(--kappel);
  color: var(--white);
  font-family: var(--ff-league_spartan);
  font-size: var(--fs-4);
  display: flex;
  align-items: center;
  gap: 7px;
  max-width: max-content;
  padding: 10px 20px;
  border-radius: var(--radius-5);
  overflow: hidden;
}

.has-before,
.has-after {
  position: relative;
  z-index: 1;
}

.has-before::before,
.has-after::after {
  position: absolute;
  content: "";
}

.btn::before {
  inset: 0;
  background-image: var(--gradient);
  z-index: -1;
  border-radius: inherit;
  transform: translateX(-100%);
  transition: var(--transition-2);
}

.btn:is(:hover, :focus)::before { transform: translateX(0); }

.img-holder {
  aspect-ratio: var(--width) / var(--height);
  background-color: var(--light-gray);
  overflow: hidden;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-subtitle {
  font-size: var(--fs-5);
  text-transform: uppercase;
  font-weight: var(--fw-500);
  letter-spacing: 1px;
  text-align: center;
  margin-block-end: 15px;
}

.section-text {
  font-size: var(--fs-5);
  text-align: center;
  margin-block: 15px 25px;
}

.grid-list {
  display: grid;
  gap: 30px;
}

.category-card,
.stats-card { background-color: hsla(var(--color), 0.1); }

:is(.course, .blog) .section-title { margin-block-end: 40px; }






/*-----------------------------------*\
  #HERO
\*-----------------------------------*/

.hero { padding-block-start: calc(var(--section-padding) + 80px); }

.hero .container {
  display: grid;
  gap: 40px;
}

.hero-text {
  color: var(--eerie-black-1);
  font-size: var(--fs-4);
  text-align: center;
  margin-block: 18px 20px;
}

.hero .btn { margin-inline: auto; }

.hero-banner {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  align-items: flex-start;
  gap: 30px;
}

.hero-banner .img-holder.one {
  border-top-right-radius: 70px;
  border-bottom-left-radius: 110px;
}

.hero-banner .img-holder.two {
  border-top-left-radius: 50px;
  border-bottom-right-radius: 90px;
}












/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for large than 575px screen
 */

@media (min-width: 575px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 520px;
    width: 100%;
    margin-inline: auto;
  }

  .grid-list { grid-template-columns: 1fr 1fr; }

  :is(.course, .blog) .grid-list { grid-template-columns: 1fr; }



 


  /**
   * HERO
   */

  .hero-banner { grid-template-columns: 1fr 0.9fr; }



}





/**
 * responsive for large than 768px screen
 */

@media (min-width: 768px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 4.6rem;
    --fs-2: 3.8rem;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 720px; }

  .btn { padding: 15px 30px; }

  :is(.course, .blog) .grid-list { grid-template-columns: 1fr 1fr; }



 


  /**
   * HERO
   */

  .hero { padding-block-start: calc(var(--section-padding) + 90px); }

  .hero .container { gap: 50px; }

  .hero-text { margin-block-end: 30px; }

  .hero-banner {
    position: relative;
    z-index: 1;
  }

  .hero-banner .img-holder { max-width: max-content; }

  .hero-banner .img-holder.one { justify-self: flex-end; }

  .hero-banner .img-holder.two { margin-block-start: 100px; }

  .hero-shape-1 {
    display: block;
    position: absolute;
    bottom: -40px;
    left: -10px;
  }



 


  

}





/**
 * responsive for large than 992px screen
 */

@media (min-width: 992px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 5.5rem;
    --fs-2: 4.5rem;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 960px; }

  .grid-list { grid-template-columns: repeat(4, 1fr); }

  :is(.course, .blog) .grid-list { grid-template-columns: repeat(3, 1fr); }



  /**
   * HERO
   */

  .hero .container {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .hero .section-title,
  .hero-text { text-align: left; }

  .hero .btn { margin-inline: 0; }



  



  



 
}





/**
 * responsive for large than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 6.5rem;

    /**
     * spacing
     */

    --section-padding: 120px;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 1185px; }

  .shape { display: block; }

  .about-content,
  .video-card,
  .blog { position: relative; }



  
  

  @keyframes slideIn {
    0% { transform: translateY(-100%); }
    100% { transform: translateY(0); }
  }

  

  

 
  .header .btn { margin-inline-end: 0; }



  /**
   * HERO
   */

  .hero { padding-block-start: calc(var(--section-padding) + 120px); }

  .hero .container { gap: 80px; }

  .hero-shape-2 {
    top: -80px;
    z-index: -1;
  }



  


  

  

}